let colours = {
  white: 'rgb(255, 255, 255)',
  greyVeryLight: 'rgb(233, 236, 239)',
  greyLight: 'rgb(171, 177, 182)',
  grey: 'rgb(108, 117, 125)',
  greyDark: 'rgb(57, 62, 68)',
  greyVeryDark: 'rgb(26, 29, 32)',
  black: 'rgb(0, 0, 0)',
  blueLight: 'rgb(150, 240, 253)',
  blue: 'rgb(110, 220, 253)',
  blueDark: 'rgb(70, 200, 253)',
  blueVeryDark: 'rgb(30, 180, 253)',
  redLight: 'rgb(253, 190, 220)',
  red: 'rgb(253, 170, 210)',
  redDark: 'rgb(253, 150, 200)',
  blueBack: 'rgba(110, 220, 253, 0.5)',
  redError: 'rgba(253, 170, 210, 0.5)',
  drawerText: 'rgb(26, 29, 32)',
  headerBack: 'rgb(26, 29, 32)',
  homeText: 'rgb(84, 84, 84)',
  homeBackground: 'rgb(239,	234, 232)',
}

const today = new Date()
const [day, month] = [today.getDate(), today.getMonth() + 1]

// Christmas theme!
if ((month === 11 && day >= 10) || month === 12)
  colours = {
    ...colours,
    blueLight: 'rgb(230, 36, 41)',
    blue: 'rgb(210, 23, 40)',
    blueDark: 'rgb(100, 215, 90)',
    blueVeryDark: 'rgb(70, 210, 60)',
    blueBack: 'rgba(150, 225, 150, 0.3)',
    drawerText: 'rgb(0, 40, 0)',
    headerBack: 'rgb(0, 40, 0)',
  }

export { colours }

export const logos = {
  instagram: '/Logo-Instagram.png',
  facebook: '/Logo-Facebook.png',
  etsyUS: '/Logo-Etsy-US.png',
  etsyEU: '/Logo-Etsy-EU.png',
}

export const links = {
  instagram: 'https://www.instagram.com/ged_homemade/',
  facebook: 'https://www.facebook.com/gedhomemade/',
  etsyUS: 'https://www.etsy.com/shop/homemadebyGED/',
  etsyEU: 'https://www.etsy.com/it/shop/GEDteamita',
}

export const titlesRegistry = {
  colors: 'Colors',
  products: 'Products',
  sizes: 'Sizes',
  stores: 'Stores',
}

export const titlesItem = {
  check: 'Confirm?',
  success: 'Done',
  failure: 'Error',
}

export const phrasesItem = {
  check: 'Click OK to proceed',
  success: 'Item processed successfully',
  failure: 'Error processing item',
}

export const titlesInventory = {
  add: 'Add item',
  update: 'Update item',
  transfer: 'Transfer item',
  delete: 'Delete item',
}

export const phrasesInventory = {
  delete: 'You are about to delete the item from the inventory.',
}

export const titlesSales = {
  add: 'Add sale',
  update: 'Update sale',
}
