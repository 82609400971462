import React from 'react'
import { Chip } from '@mui/material'

import { colours } from '../settings/settings'

const ChipColor = ({ label, hexCode }) => {
  const red = parseInt(hexCode?.substring(0, 2), 16)
  const green = parseInt(hexCode?.substring(2, 4), 16)
  const blue = parseInt(hexCode?.substring(4, 6), 16)

  const luminance = 0.21 * red + 0.72 * green + 0.07 * blue

  return (
    <Chip
      label={label}
      sx={{
        color: (luminance < 128 && colours.white) || colours.black,
        bgcolor: `#${hexCode}`,
      }}
    />
  )
}

export default ChipColor
