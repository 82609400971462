import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Chip, Grid } from '@mui/material'

import ChipColor from './ChipColor'
import InputField from './InputField'
import { colours } from '../settings/settings'
import { errorFunctions } from '../hooks/error-functions'
import { SET_NEW_QUANTITY, SET_NEW_PRICE } from '../container/inventory/types'

const DialogUpdateInventory = ({
  colors,
  sizes,
  selectedInventory,
  newQuantity,
  newPrice,
}) => {
  const dispatch = useDispatch()

  const mode = 'update'

  // error functions
  const { errorNaturale, errorPositivo } = errorFunctions()

  // set inputs
  useEffect(() => {
    dispatch({ type: SET_NEW_QUANTITY, payload: selectedInventory?.quantity })
    dispatch({ type: SET_NEW_PRICE, payload: selectedInventory?.price })
  }, [dispatch, selectedInventory])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_NEW_QUANTITY })
      dispatch({ type: SET_NEW_PRICE })
    }
  }, [dispatch])

  return (
    <>
      &nbsp;
      {(!selectedInventory && (
        <Grid item xs={12}>
          No item selected.
        </Grid>
      )) || (
        <Grid container rowSpacing={3} columnSpacing={1} alignItems='center'>
          <Grid item xs sx={{ whiteSpace: 'nowrap' }}>
            <center>
              <b>{selectedInventory?.product}</b>
            </center>
          </Grid>
          {selectedInventory?.size && (
            <Grid item xs>
              <center>
                <Chip
                  label={
                    sizes?.find(x => x.name === selectedInventory?.size)?.code
                  }
                  sx={{ bgcolor: colours.greyVeryLight }}
                />
              </center>
            </Grid>
          )}
          {selectedInventory?.color && (
            <Grid item xs>
              <center>
                <ChipColor
                  label={selectedInventory?.color}
                  hexCode={
                    colors?.find(c => c.name === selectedInventory?.color)
                      ?.hexCode
                  }
                />
              </center>
            </Grid>
          )}
          <Grid item xs sx={{ whiteSpace: 'nowrap' }}>
            <center>{selectedInventory?.store}</center>
          </Grid>
          <Grid container item xs={12} spacing={3} alignItems='center'>
            <Grid item xs={12} md={6}>
              <center>
                <InputField
                  id='quantity'
                  value={newQuantity}
                  onChange={i =>
                    dispatch({ type: SET_NEW_QUANTITY, payload: i })
                  }
                  autoFocus={true}
                  label='Quantity'
                  errorFunc={errorNaturale}
                  type='number'
                  mode={mode}
                />
              </center>
            </Grid>
            <Grid item xs={12} md={6}>
              <center>
                <InputField
                  id='price'
                  value={newPrice}
                  onChange={i => dispatch({ type: SET_NEW_PRICE, payload: i })}
                  label='Price'
                  errorText='Cannot be negative'
                  errorFunc={i => i && errorPositivo(i)}
                  type='number'
                  mode={mode}
                />
              </center>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  colors: state.home.colors,
  sizes: state.home.sizes,
  selectedInventory: state.inventory.selectedInventory,
  newQuantity: state.inventory.newQuantity,
  newPrice: state.inventory.newPrice,
})

const ConnectedDialogUpdateInventory = connect(mapStateToProps)(
  DialogUpdateInventory
)

export default ConnectedDialogUpdateInventory
